<template>
    <div class="banner">
        <div class="bac-girl" :style="{background: 'url(' + img + ') no-repeat right bottom'}" />
        <div class="content">
            <b-row>
                <b-col md="12" xl="8" class="mr-auto">
                    <div class="bordered-text">Минимальная комиссия от 950 руб. и КВ до 50%</div>
                    <h1 class="main-title">Банковские гарантии<br/>для ваших клиентов</h1>
                    <div class="min-title">
                        Выпускайте гарантии онлайн и получайте комиссию
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-xl-12">
                            <div class="row">
                                <a class="btn btn-green col-6 me-4 mb-3" :href="agentUrl">Стать агентом</a>
                                <a class="btn btn-green-secondary col-6 mb-3" :href="clientUrl">Стать клиентом</a>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Img from '@/assets/banner.png'
export default {
    name: "Banner",
    data() {
        return {
            agentUrl: process.env.VUE_APP_NEW_LK_REGISTER,
            clientUrl: process.env.VUE_APP_BASE_CLIENT_URL_LK,
            img: Img
        }
    }
}
</script>
<style lang="scss">
.banner {
    background: linear-gradient(105.01deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.02));
    border-radius: 20px;
    position: relative;
    .bac-girl {
        position: absolute;
        top: 100px;
        bottom: 0;
        left: 0;
        right: 70px;
        z-index: -1;
    }

    .content {
        padding: 120px 80px;

        .bordered-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            background: linear-gradient(105.01deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.02));
            border-radius: 20px;
            border: 1px solid #aaa;
            padding: 10px 15px;
            display: inline-block;
        }

        .min-title {
            margin-bottom: 80px;
        }
    }
}
@media (max-width: 1440px) {
    .banner .min-title {
        max-width: 360px;
    }
}
@media (max-width: 1080px) {
    .bac-girl {
        display: none;
    }
    .banner .min-title {
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .banner .content {
        padding: 40px 40px;
    }
}
</style>