<template>
        <b-navbar toggleable="md" type="light" :fixed="fixedTop">
            <b-container>
                <b-navbar-brand href="/" class="logo">
                    <img :src="require('@/assets/logo.svg')" alt="LikeBG">
                </b-navbar-brand>

                <b-button class="menu-toggle" variant="light" @click="showMenu">
                    <b-icon icon="list"></b-icon>
                </b-button>
                <div class="mobile-menu" :class="{show: isShowMenu}">
                    <b-container>
                        <div class="mobile-menu__header">
                            <b-navbar-brand href="/" class="logo">
                                <img :src="require('@/assets/logo.svg')" alt="LikeBG">
                            </b-navbar-brand>
                            <b-button class="menu-toggle" variant="light" @click="showMenu">
                                <b-icon icon="x"></b-icon>
                            </b-button>
                        </div>
                        <b-navbar-nav class="left-menu">
                            <b-nav-item @click="isShowMenu=false" href="#banks">Банки</b-nav-item>
                            <b-nav-item target="_blank" :href="urlDoc">Документы</b-nav-item>
                        </b-navbar-nav>
                    </b-container>
                        <div class="mobile-menu__bottom">
                            <b-container>
                                <div class="menu-item">
                                    <a class="menu-link" :href="`tel:${phone}`">{{ phone }}</a>
                                </div>
                                <div class="menu-item">
                                    <a class="menu-link" :href="`mailto:${email}`">{{ email }}</a>
                                </div>
                                <a class="btn btn-green d-md-none" :href="agentUrl">Личный
                                    кабинет
                                </a>
                            </b-container>
                        </div>
                </div>
                    <div class="w-100 d-md-flex d-none justify-content-between align-items-center ">
                        <b-navbar-nav class="left-menu">
                            <b-nav-item href="#banks">Банки</b-nav-item>
                            <b-nav-item target="_blank" :href="urlDoc">Документы</b-nav-item>
                        </b-navbar-nav>
                        <div class="d-flex align-items-center">
                            <b-navbar-nav class="right-menu">
                                <b-nav-item class="desktop" href="tel:+78005552030">{{ phone }}</b-nav-item>
                                <b-nav-item class="desktop" :href="`mailto:${email}`">{{ email }}</b-nav-item>
                                <b-nav-item class="mobile circle" href="tel:+78005552030">
                                    <img :src="require('@/assets/phone.svg')" alt="phone">
                                </b-nav-item>
                                <b-nav-item class="mobile circle" :href="`mailto:${email}`">
                                    <img :src="require('@/assets/email.svg')" alt="email">
                                </b-nav-item>
                            </b-navbar-nav>
                            <a class="btn btn-green d-none d-lg-flex" :href="`${agentUrl}`">
                                Личный кабинет
                            </a>
                            <a class="btn-green-circle d-lg-none d-md-flex d-none" :href="`${agentUrl}`">
                                <img :src="require('@/assets/user.svg')" alt="lk">
                            </a>
                        </div>
                    </div>

            </b-container>
        </b-navbar>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            phone: '8 800 234 49 24',
            email: 'info@likebg.ru',
            fixedTop: '',
            agentUrl: process.env.VUE_APP_BASE_URL_LK,
            clientUrl: process.env.VUE_APP_BASE_CLIENT_URL_LK,
            urlDoc: process.env.VUE_APP_NEW_LK_DOCS,
            isShowMenu: false,
            expanded: true
        }
    },
   created () {
       window.addEventListener('scroll', this.onScroll);
   },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll(e) {
            const top = e.srcElement.scrollingElement.scrollTop;
            if (top > 100) {
                this.fixedTop = 'top'
            } else {
                this.fixedTop = ''
            }
        },
        showMenu() {
            this.isShowMenu = !this.isShowMenu
        }
    }
}
</script>

<style lang="scss">
.navbar.navbar-light {
    padding-top: 20px;
    padding-bottom: 20px;
    background: transparent;
    &.fixed-top {
        background: #fff !important;
    }

    .menu-toggle {
        background: transparent;
        border: 0;
        font-size: 200%;
        display: none;
    }

    .logo {
        margin-right: 62px;
        margin-top: -15px;
        width: 150px;
        display: block;

        img {
            width: 100%;
        }
    }

    .navbar-nav {
        &.left-menu .nav-link {
            color: #000;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-right: 34px;

            &:hover {
                color: #048841;
            }
        }

        &.right-menu .nav-link {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #000;
            background: #F1F4F9;
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.02);
            border-radius: 20px;
            margin-right: 30px;
            padding: 10px 20px;

            &:hover {
                background: #FFFFFF;
                box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.02);
                border-radius: 50px;
                color: #048841;
            }
        }
    }
    .mobile-menu {
        display: none;
    }

    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }

    .btn.btn-green,
    .btn.btn-green-secondary {
        height: 60px;
        width: 250px;
        font-size: 16px;
        line-height: 19px;
    }
}

@media (max-width: 1440px) {
    .navbar.navbar-light {
        .navbar-nav {
            &.left-menu .nav-link {
                margin-right: 20px;
                font-size: 20px;
                line-height: 24px;
            }

            &.right-menu .nav-item {
                .nav-link{
                    height: 60px;
                    width: 60px;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .desktop {
            display: none;
        }

        .mobile {
            display: flex;
        }

        .logo {
            margin-right: 70px;
            width: 130px;
            margin-top: -15px;
            img {
                width: 130px;
            }
        }
    }
}
@media (max-width: 767px) {
    .navbar.navbar-light {
        .mobile {
            display: none;
        }
        .mobile-menu {
            display: block;
            position: fixed;
            list-style:none;
            padding: 10px;
            margin: 0;
            box-sizing: border-box;
            width: 100%;
            background-color: #fff;
            height: 100%;
            top: 0;
            left: -100%;
            transition: left .2s;
            z-index: 2;
            -webkit-transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            &.show {
                left: 0;
            }
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 20px;
                padding-bottom: 40px;
            }
            &__bottom {
                position: absolute;
                bottom: 20px;
                left:10px;
                right: 10px;
                .menu-link {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000;
                    margin-bottom: 20px;
                    text-decoration: none;
                    padding-bottom: 3px;
                    border-bottom: 1px solid #E6E6E6;
                    &:hover {
                        color: #08A651
                    }
                }
            }
            .btn.btn-green,
            .btn.btn-green-secondary {
                margin-top: 50px;
                width: 100%;
            }
        }
        .menu-toggle  {
            display: block;
            margin-right: -10px;
        }
    }
}
</style>